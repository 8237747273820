import React from 'react'
import {
  AccountBalance as IconAccountBalance,
  Assessment as IconAssessment,
  Assignment as IconAssignment,
  AssignmentInd as IconAssignmentInd,
  Dashboard as IconDashboard,
  Description as IconDescription,
} from '@material-ui/icons'
import ReferralList from './components/Referrals/ReferralList'
import { CreateReferral, EditReferral } from './components/ReferralForm'
import ReferralInvoiceList from './components/ReferralInvoice/ReferralInvoiceList'
import { BillingDashboard } from './components/BillingDashboard'
import Dashboard from './components/Dashboard'
import { FindMember } from './components/FindMember'
import { Profile } from './components/Profile'
import { Callback } from './components/Login'
import UserManagement from './components/UserManagement'
import UM_UserDetail from './components/UserManagement/UserDetail'
import UM_PracticeFacilityDetail from './components/UserManagement/PracticeFacilityDetail'
import ReferralInvoiceForm from './components/ReferralInvoiceForm'
import {
  CreateReferralRequest,
  EditReferralRequest,
} from './components/ReferralRequestForm'
import Reports from './components/Reports'
import NoUser from './components/NoUser'
import NoPermissions from './components/NoUser/NoPermissions'
import { DownloadFile } from './components/DownloadFile'
import { Redirect, Route, Switch as RouteSwitch } from 'react-router-dom'
import { useAuth } from './providers/Auth'

function canAccessDashboard(access: any): boolean {
  return (
    access?.CanAccessInvoices ||
    access?.CanAccessReferrals ||
    access?.CanManageUsers
  )
}

function canAccessReports(access: any): boolean {
  return (
    access?.Roles?.OrganizationReportsViewer ||
    access?.Roles?.ReferralInvoiceReviewer ||
    access?.Roles?.ReferrerReportsViewer
  )
}

export const routes = [
  {
    from: '/',
    exact: true,
    redirect: (access: any): string => {
      if (canAccessDashboard(access)) {
        return '/dash'
      }

      if (canAccessReports(access)) {
        return '/reports'
      }

      return '/no_permissions'
    },
  },
  {
    path: '/dash',
    component: Dashboard,
    exact: true,
    label: 'Dashboard',
    icon: IconDashboard,
    permCheck: canAccessDashboard,
  },
  { path: '/login_callback', component: Callback, exact: true },
  { path: '/profile', component: Profile, exact: true },
  { path: '/find_member', component: FindMember, exact: true },
  { path: '/no_user', component: NoUser, exact: true },
  { path: '/no_permissions', component: NoPermissions, exact: true },
  { path: '/download', component: DownloadFile, exact: true },
  {
    path: '/referrals',
    component: ReferralList,
    label: 'Referrals',
    icon: IconAssignment,
    permCheck(access: any): boolean {
      return access?.CanAccessReferrals
    },
  },
  {
    path: '/referral/create',
    component: CreateReferral,
    permCheck(access: any): boolean {
      return access?.CanAccessReferrals
    },
  },
  {
    path: '/referral/:id',
    component: EditReferral,
    permCheck(access: any): boolean {
      return access?.CanAccessReferrals
    },
  },
  { path: '/referral_request/create', component: CreateReferralRequest },
  {
    path: '/referral_request/:referralRequestId',
    component: EditReferralRequest,
  },
  {
    path: '/billing_dashboard',
    component: BillingDashboard,
    label: 'Billing Dashboard',
    icon: IconAccountBalance,
    permCheck(access: any): boolean {
      return access?.CanAccessInvoices
    },
  },
  {
    path: '/referral_invoices',
    component: ReferralInvoiceList,
    label: 'Invoices',
    icon: IconDescription,
    permCheck(access: any): boolean {
      return access?.CanAccessInvoices
    },
  },
  {
    path: '/referral_invoice/:id',
    component: ReferralInvoiceForm,
    permCheck(access: any): boolean {
      return access?.CanAccessInvoices
    },
  },
  {
    path: '/reports',
    component: Reports,
    label: 'Reports',
    icon: IconAssessment,
    permCheck: canAccessReports,
  },
  {
    path: '/user_management',
    component: UserManagement,
    exact: true,
    label: 'User Management',
    icon: IconAssignmentInd,
    permCheck(access: any): boolean {
      return access?.CanManageUsers
    },
  },
  {
    path: '/user_management/organization/:organizationId',
    component: UserManagement,
    exact: true,
  },
  {
    path: '/user_management/organization/:organizationId/users/:userId/facility_permissions',
    component: UM_UserDetail,
  },
  {
    path: '/user_management/organization/:organizationId/facilities/:facilityId/user_permissions',
    component: UM_PracticeFacilityDetail,
  },
]

export default function RenderRoutes() {
  const { access } = useAuth() as any
  return (
    <RouteSwitch>
      {routes.map((r, index) => {
        if (r.redirect) {
          const { from, redirect, ...rest } = r
          return (
            <Redirect
              key={`${index}:${from}:${redirect}`}
              from={from}
              to={redirect(access)}
              {...rest}
            />
          )
        }

        const { component: Component, permCheck, ...routeProps } = r

        return (
          <Route
            key={`${index}:${r.path}`}
            {...routeProps}
            render={(props) => (
              <DisplayRoute
                {...props}
                Component={Component}
                permCheck={permCheck}
              />
            )}
          />
        )
      })}
    </RouteSwitch>
  )
}

function DisplayRoute(props: any) {
  const { Component, permCheck, ...forwardProps } = props

  const { access } = useAuth() as any
  const canView = React.useMemo(() => {
    if (!access) return false
    if (!permCheck) return true
    return permCheck(access)
  }, [access])

  if (!canView) {
    return (
      <div>
        <p>
          <strong>This page requires additional permission.</strong>
          <br />
          <br />
          <em>
            You do not have access to view this area. Ask your GoZERO
            Administrator for access to this page.
          </em>
        </p>
      </div>
    )
  }

  return <Component {...forwardProps} />
}
