import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid, Paper, Typography } from '@material-ui/core'
import ReferralForm from './ReferralForm'
import { LoadingSidebar } from './LoadingSkeletons'
import ConversationsTabs from '../Conversations'
import { ReferralActivity } from '../ReferralActivity'
import useApi from '../../hooks/useApi'
import PermissionDenied from '../PermissionDenied'
import { useSetFrameHeaderLeft, useSetFrameHeaderMid } from '../AppFrame'
import useTourDriver from '../../hooks/useTourDriver'
import dateTime from '../../utils/dateTime'
import DesignSuite24 from '../DesignSuite24'

const useReferral = (referralId: string) => {
  const { data, refetch } = useApi({
    route: `/referral/${referralId}`,
  })

  if (data) {
    const { Referral, ...rest } = data
    return { referral: { ...Referral, ...rest }, refetch }
  }
  return { referral: null, refetch }
}

export const EditReferral: React.FC<any> = (props: any) => {
  const referralId = props.match.params.id
  const { referral, refetch } = useReferral(referralId)

  // permissions are implied, not explicit
  // so it's possible that an EDITOR will have view=false
  if (referral && referral.View === false && referral.Edit === false) {
    return <PermissionDenied />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <ReferralForm initialData={referral} isEditing refetch={refetch} />
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          background: '#f1f1f1',
          borderRadius: 8,
          boxShadow: '15px 0px 26px -30px inset',
          padding: '1rem',
          height: 'calc(100vh - var(--header-height) - 1rem)',
          overflowY: 'scroll',
        }}>
        {referral ? (
          <div>
            <Paper>
              <ConversationsTabs referralId={referralId} />
            </Paper>
            {/* <Paper style={{ marginTop: '20px' }}> */}
            <Typography variant="h6" style={{ margin: '1rem 0 0.5rem' }}>
              Referral Activity
            </Typography>
            <ReferralActivity referralId={referralId} />
            {/* </Paper> */}
          </div>
        ) : (
          <LoadingSidebar />
        )}
      </Grid>
      <HackJobShowHeaders referral={referral} />
    </Grid>
  )
}

function HackJobShowHeaders({ referral }: any) {
  const tourDriver = useTourDriver('referralPage')
  tourDriver.addStep({
    key: tourDriver.makeKey(),
    selector: '#tour-member-header-details',
    side: 'bottom',
  })
  tourDriver?.addStep({
    key: tourDriver.makeKey(),
    selector: '#tour-member-details',
    side: 'right',
  })
  tourDriver?.addStep({
    key: tourDriver.makeKey(),
    selector: '#tour-referral-details',
  })
  tourDriver?.addStep({
    key: tourDriver.makeKey(),
    selector: '#tour-exclusions',
    side: 'right',
  })

  useSetFrameHeaderLeft(
    () => (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          columnGap: '0.5rem',
        }}>
        <Typography variant="h5">Referral</Typography>
        <tourDriver.BtnReplayTour />
      </div>
    ),
    [tourDriver]
  )

  useSetFrameHeaderMid(() => {
    if (!referral?.EmployerMember) return null
    const em = referral.EmployerMember
    const isActive = em.Active || em.IsCurrentlyActive
    const datesStyle = isActive
      ? {}
      : { color: 'firebrick', fontWeight: 'bold' }

    return (
      <Typography
        id="tour-member-header-details"
        data-tour-title="Referral Glance"
        data-tour-descr="Immediately pertinent member info is quickly available in the page header."
        variant="h6"
        style={{ lineHeight: 1, display: 'inline-flex', alignItems: 'center' }}>
        {em.FirstName} {em.LastName}
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.4,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>DOB</strong>
          {dateTime.parse(em.DOB).format()}
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.4,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>Integration ID</strong>
          {em.IntegrationID}
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.4,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>Elig Active Dates</strong>
          {isActive ? (
            <span>
              {dateTime.parse(em.ActivationDate).format()}
              &nbsp;
              <DesignSuite24.CommonIcons.IconArrowRight fontSize="inherit" />
              &nbsp;
              {dateTime
                .parse(em.DeactivationDate)
                .format(dateTime.formats.AmericanDate, '(no term date)')}
            </span>
          ) : (
            <span style={datesStyle}>CURRENTLY INACTIVE</span>
          )}
        </Typography>
      </Typography>
    )
  }, [referral?.EmployerMember])

  return null
}

export const CreateReferral: React.FC<any> = (props: any) => {
  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">New Referral</Typography>
  ))

  const member = React.useMemo(() => {
    return props.location.state && props.location.state.record
  }, [props.location.state])

  const { data: memberContact } = useApi(
    member && member.MemberID
      ? {
          route: `/member/${member.MemberID}/contact_info`,
        }
      : undefined
  )

  const initialData = React.useMemo(() => {
    return {
      ...member,
      ContactPhone: memberContact?.Phone,
      ContactPhoneExt: memberContact?.PhoneExt,
      ContactEmail: memberContact?.Email,
    }
  }, [member, memberContact])

  if (!member) return <Redirect to="/find_member" />
  if (!memberContact) return null

  return <ReferralForm initialData={initialData} />
}
