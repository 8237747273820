import React, { useState } from 'react'
import {
  Button,
  SortDirection,
  TextField as MUITextField,
  Typography,
} from '@material-ui/core'
import DataTable from '../DataTable'
import { ReferralModel } from '../../models/referral'
import { useReferralFilters, useReferralList } from './hooks'
import AddNewReferralBtn from './AddNewReferralButton'
import useQueryParams from '../../hooks/useQueryParams'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { Archive } from '@material-ui/icons'
import { archiveReferral } from '../../actions/ReferralActions'
import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import SelectField from '../SelectField'
import useApi from '../../hooks/useApi'
import SwitchField from '../SwitchField'
import Tooltip from '../Tooltip'
import ManagedDateInput from '../Inputs/managedDateInput'
import { useFrameHeightLock, useSetFrameHeaderLeft } from '../AppFrame'
import styled from 'styled-components'

const StyledReferralList = styled.div`
  height: 100%;
  display: flex;

  .referral-filters {
    padding: 1rem;
    width: 240px;
  }

  .data-table-wrapper {
    margin: 0 !important;
    height: 100%;

    .data-table-wrapper-l2 {
      height: 100%;

      table {
        max-height: 100%;

        thead {
          position: sticky;
          top: 0;
          background: #e9eef9;
          z-index: 2;
          white-space: nowrap;
        }

        th,
        td {
          padding: 0.5rem;
        }
      }

      .data-table-footer {
        position: sticky;
        bottom: 0;
        background: #fff;
        width: 100%;
        border-top: 1px solid #e0e0e0;
        z-index: 2;
      }
    }
  }
`

const ReferralList: React.FC = () => {
  const { params, setParams } = useQueryParams()
  const { data, meta, refetch, loading } = useReferralList(params)
  const [selected, setSelected] = useState<Array<number>>([])
  const { show: showSnackbar } = useSnackbar()

  useFrameHeightLock(() => true, [])

  useSetFrameHeaderLeft(() => <Typography variant="h5">Referrals</Typography>)

  const onChangePage = (change: any) => {
    const { range, page, pageSize } = change
    setParams({ ...params, range })
    refetch({ ...params, range })
  }

  const handleCheck = (rows: Array<any>) => {
    setSelected(rows.map((row) => row.ID))
  }

  const isRowCheckable = (row: any) => {
    return row.ArchivedAt === null
  }

  const onArchiveRow = (id: number) => {
    refetch({ ...params })
  }

  const handleBulkArchive = () => {
    archiveReferral(selected)
      .then(() => {
        refetch({ ...params })
        showSnackbar('Successfully Archived Referrals', SnackbarTypeSuccess)
      })
      .catch((err: string) => {
        showSnackbar(err, SnackbarTypeError)
      })
  }

  const {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    selectedReferrer,
    setSelectedEmployer,
    setSelectedReferrer,
    archived,
    setArchived,
    isUrgent,
    setIsUrgent,
  } = useReferralFilters(refetch)

  const { data: organizations } = useApi({
    route: '/referral_organization_filter',
  })

  const { data: statuses } = useApi({
    route: '/combined_statuses',
  })

  const { data: employers } = useApi({
    route: '/referral_employers',
  })

  const { data: referrers } = useApi({
    route: '/referral_referrers',
  })

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    setParams({ ...params, sort: [col, dir] })
    refetch({ ...params, sort: [col, dir] })
  }

  const sortable = params.sort
    ? { col: params.sort[0], dir: params.sort[1] }
    : undefined

  if (!data || !meta) return null
  return (
    <StyledReferralList>
      <div className="referral-filters">
        {!!selected?.length && (
          <div>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleBulkArchive}
              startIcon={<Archive />}
              fullWidth>
              Archive Selected Referrals
            </Button>
            <hr style={{ margin: '1rem 0' }} />
          </div>
        )}
        <AddNewReferralBtn stackButtons />
        <hr style={{ margin: '1rem 0 1.5rem' }} />
        <div>
          <div style={{ paddingBottom: '1rem' }}>
            <SearchField
              useV2Style
              label="Search Referrals"
              value={searchText}
              onChange={setSearchText}
            />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <ManagedDateInput
              label="DOB"
              value={searchDob}
              setter={({ name, value }) => setSearchDob(value)}
              fullWidth
            />
          </div>
          {organizations && organizations.length > 1 && (
            <div style={{ paddingBottom: '1rem' }}>
              <AutocompleteField
                label="Organization"
                value={selectedOrg}
                onChange={setSelectedOrg}
                options={organizations.map((org: any) => ({
                  name: org.OrganizationName,
                  value: org.OrganizationID,
                }))}
                PassthroughProps={{
                  renderInput: (params: any, label: any, error: any) => {
                    return (
                      <MUITextField
                        {...params}
                        variant="outlined"
                        size="small"
                        margin="none"
                        label="Organization"
                        error={!!error}
                        helperText={error}
                        InputLabelProps={{ shrink: true }}
                      />
                    )
                  },
                }}
              />
            </div>
          )}
          <div style={{ paddingBottom: '1rem' }}>
            <SelectField
              // useV2Style
              multiple
              label="Status"
              value={selectedStatus}
              onChange={setSelectedStatus}
              options={statuses.map((stat: any) => ({
                name: stat.Descr,
                value: stat.ID,
              }))}
            />
          </div>

          {employers && employers.length > 1 && (
            <div style={{ paddingBottom: '1rem' }}>
              <SelectField
                // useV2Style
                multiple
                label="Employer"
                value={selectedEmployer}
                onChange={setSelectedEmployer}
                options={employers.map((stat: any) => ({
                  name: stat.EmployerName,
                  value: stat.EmployerID,
                }))}
              />
            </div>
          )}
          {referrers && referrers.length > 1 && (
            <div style={{ paddingBottom: '1rem' }}>
              <SelectField
                // useV2Style
                multiple
                label="Referrer"
                value={selectedReferrer}
                onChange={setSelectedReferrer}
                options={referrers.map((stat: any) => ({
                  name: stat.ReferrerName,
                  value: stat.ReferrerID,
                }))}
              />
            </div>
          )}
        </div>

        <SwitchField
          label="Show Archived"
          value={archived}
          onChange={setArchived}
        />
        <Tooltip title="Applies a filter to display only referrals marked as urgent">
          {/* SwitchField doesn't accept refs, which is required for tooltips; wrapping in a div fixes this */}
          <div>
            <SwitchField
              label="Show Only Urgent"
              value={isUrgent}
              onChange={setIsUrgent}
            />
          </div>
        </Tooltip>
      </div>
      <DataTable
        keyProp="ID"
        data={data}
        checkHandler={handleCheck}
        isRowCheckable={isRowCheckable}
        columns={ReferralModel({ onArchive: onArchiveRow })}
        count={meta.Total}
        initPage={1}
        initPageSize={25}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangePage}
        sortHandler={sortColumns}
        sortable={sortable}
        loading={loading}
        allowEditing={false}
      />
    </StyledReferralList>
  )
}

export default ReferralList
