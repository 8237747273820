import React from 'react'
import { Card, CardContent } from '@material-ui/core'

export default function NoPermissions(): React.ReactElement {
  return (
    <Card>
      <CardContent>
        There are no permissions associated with this account. Please contact
        your GoZERO Administrator for assistance.
      </CardContent>
    </Card>
  )
}
