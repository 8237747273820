import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { getEmployerExclusions } from '../../actions/EmployerActions'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from '@material-ui/core'
import {
  CheckCircle as IconCheckCircle,
  ExpandMore as ExpandMoreIcon,
  Timelapse as IconTimelaps,
} from '@material-ui/icons'
import useLocalStorageCache, {
  lastValueKeys,
} from '../../hooks/useLocalStorageCache'
import { ShapePlanExclusion } from './types'
import dateTime from '../../utils/dateTime'

const StyledExclusions = styled.div`
  padding-top: 1rem;

  ul.icon-list {
    list-style: none;
    padding: 0;
    margin: 0 0 0.5rem 0;
    color: #777;

    & > li {
      vertical-align: middle;
      line-height: 1;

      & svg {
        vertical-align: middle;
        display: inline-block;
      }
      & span {
        vertical-align: middle;
      }
    }
  }

  .chipper {
    margin: 0 0.5rem 0.5rem 0;
  }

  .exclusion-details {
    padding-top: 0.5rem;
  }
`

interface exclusionProps {
  employerID: number | null
}

export default function Exclusions({
  employerID,
}: exclusionProps): React.ReactElement | null {
  const [visibleExclusion, setVisibleExclusion] =
    React.useState<ShapePlanExclusion | null>(null)
  const [isAccordionOpen, setIsAccordionOpen] = useLocalStorageCache(
    lastValueKeys.ExclusionsAccordionOpen,
    true
  )
  const [list, setList] = useState<any>([])
  const [hasInvalidDates, setHasInvalidDates] = useState<boolean>(false)
  const [deductibleStart, setDeductibleStart] = useState<string | null>(null)
  const [deductibleEnd, setDeducibleEnd] = useState<string | null>(null)
  const exclusions = useMemo((): ShapePlanExclusion[] => {
    return list.filter((ex: any) => ex.Status > 0)
  }, [list])

  useEffect(() => {
    if (!employerID) return
    getEmployerExclusions({ employerID }).then((res) => {
      if (res.error) throw res
      setList(res.Data.PlanExclusions || [])

      if (!res.Data?.DeductibleStartMonth || !res.Data?.DeductibleStartDay) {
        setHasInvalidDates(true)
        return
      }

      const deductibleDate = dateTime.fromMonthAndDay(
        res.Data.DeductibleStartMonth,
        res.Data.DeductibleStartDay
      )
      if (!deductibleDate.isValid()) {
        setHasInvalidDates(true)
        return
      }
      setDeductibleStart(
        deductibleDate.format(dateTime.formats.AmericanDateMonthDayOnly)
      )
      setDeducibleEnd(
        deductibleDate
          .add(-1, 'days')
          .format(dateTime.formats.AmericanDateMonthDayOnly)
      )
    })
  }, [employerID])

  const countExclusions = exclusions ? exclusions.length : 0

  const renderDates = () => (
    <>
      <strong>Accumulator Period: </strong>
      {hasInvalidDates ? 'Not Set' : `${deductibleStart} - ${deductibleEnd}`}
    </>
  )

  if (!list?.length) {
    return null
  }

  return (
    <StyledExclusions>
      <Accordion
        expanded={isAccordionOpen}
        variant="outlined"
        onChange={(_, v) => setIsAccordionOpen(v)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography className="h6">
            Exclusions <strong>({countExclusions})</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <ul className="icon-list">
              <li>
                <IconCheckCircle />{' '}
                <span>
                  indicates{' '}
                  <strong>all services in the category are excluded</strong>.
                </span>
              </li>
              <li>
                <IconTimelaps />{' '}
                <span>
                  indicates <strong>some services</strong> are excluded, and{' '}
                  <i>further information is available</i>.
                </span>
              </li>
            </ul>
            <div>
              {exclusions.map((excl) => {
                return (
                  <Chip
                    icon={
                      excl.Status === 1 ? <IconCheckCircle /> : <IconTimelaps />
                    }
                    className="chipper"
                    key={excl.Descr}
                    label={excl.Descr}
                    color="primary"
                    onClick={() => setVisibleExclusion(excl)}
                  />
                )
              })}
            </div>
            <ul className="icon-list">
              <li>
                <span>{renderDates()}</span>
              </li>
            </ul>
            {visibleExclusion ? (
              visibleExclusion.Status === 2 ? (
                <div className="exclusion-details">
                  <Typography variant="subtitle2">
                    {visibleExclusion.Descr}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ whiteSpace: 'pre-line' }}>
                    {visibleExclusion.Note}
                  </Typography>
                </div>
              ) : (
                <Typography variant="subtitle2">
                  All services related to{' '}
                  <strong>{visibleExclusion.Descr}</strong> are excluded.
                </Typography>
              )
            ) : null}
          </div>
        </AccordionDetails>
      </Accordion>
    </StyledExclusions>
  )
}
