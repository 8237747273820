import React, { useState } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'
import TextField from '../TextField'
import PhoneField, { FormatPhoneNumber } from '../PhoneField'
import PhoneExtField from '../PhoneExtField'
import { ReferralForm } from './types'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import { LoadingFormSection } from './LoadingSkeletons'
import DesignSuite24 from '../DesignSuite24'
import Masker from '../Masker'
import dateTime from '../../utils/dateTime'

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
}

// @todo: convert to dateTime
const formatDate = (date: string) => {
  return dateTime.parse(date).format(dateTime.formats.AmericanDate, 'Not Set')
}

const CurrentlyActiveField: React.FC<{ active: boolean }> = ({ active }) => {
  if (active) {
    return (
      <span
        style={{
          color: 'green',
          display: 'inline-flex',
          alignItems: 'center',
        }}>
        <CheckCircleOutline />
        &nbsp;&nbsp;Member is ACTIVE
      </span>
    )
  }
  return (
    <span
      style={{
        color: 'firebrick',
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <ErrorOutline />
      &nbsp;&nbsp;Member is INACTIVE
    </span>
  )
}

const MemberDetails: React.FC<Props> = ({ form, showErrors }) => {
  const [editEmail, setEditEmail] = useState(false)
  const [editPhone, setEditPhone] = useState(false)
  const dispEmployerTermDate = React.useMemo(() => {
    const td = dateTime
      // @ts-ignore
      .parse(form?.data?.EmployerTermDate)
    // a term date is set
    if (td.isValid()) {
      return (
        <span
          style={{
            color: 'firebrick',
            display: 'inline-flex',
            alignItems: 'center',
          }}>
          <ErrorOutline />
          &nbsp;&nbsp;{td.format(dateTime.formats.AmericanDate)}
        </span>
      )
    }

    return <span>Not Set</span>
    // @ts-ignore
  }, [form?.data?.EmployerTermDate])

  const isEditable =
    form.data.EntityName === 'referral'
      ? form.data.Edit === true && !form.data.ArchivedAt
      : form.data.ContentEditable

  const member = form.data.EmployerMember

  return (
    <div>
      <Typography variant="h5" style={{ marginBottom: '0.5rem' }}>
        <span className="header-snazzy">Member Details</span>
      </Typography>

      {!member ? (
        <LoadingFormSection />
      ) : (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'min-content 1fr',
              gridRowGap: '0.5rem',
              gridColumnGap: '1rem',
              alignItems: 'center',
              maxWidth: 450,
              fontSize: '96%',
            }}>
            <strong style={{ whiteSpace: 'nowrap' }}>Name</strong>
            <span>{`${member.FirstName} ${member.LastName}`}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Last 4 SSN
            </strong>
            <Masker value={member.SSNLast4} canToggleToView />
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Gender
            </strong>
            <span>{member.Gender}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              DOB
            </strong>
            <span>{formatDate(member.Dob || member.DOB)}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Integration ID
            </strong>
            <span>{member.IntegrationID}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap' }}>Contact Email</strong>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                columnGap: '0.5rem',
              }}>
              {editEmail || (showErrors && form.errors.ContactEmail) ? (
                <TextField
                  margin="none"
                  value={form.data.ContactEmail || ''}
                  inputProps={{ style: { fontSize: '0.8rem' } }}
                  onChange={(v: string) => form.setters.ContactEmail(v)}
                  error={showErrors ? form.errors.ContactEmail : null}
                  placeholder="Email"
                />
              ) : (
                <span>{form.data.ContactEmail}</span>
              )}

              {isEditable && (
                <div>
                  <IconButton
                    size="small"
                    onClick={() => setEditEmail((v: boolean) => !v)}>
                    <DesignSuite24.CommonIcons.IconEdit fontSize="inherit" />
                  </IconButton>
                </div>
              )}
            </div>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />

            <strong style={{ whiteSpace: 'nowrap' }}>Contact Phone</strong>
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                columnGap: '0.5rem',
              }}>
              {editPhone || (showErrors && form.errors.ContactPhone) ? (
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gridColumnGap: '0.5rem',
                    flex: 1,
                  }}>
                  <div style={{ width: 140 }}>
                    <PhoneField
                      label={null}
                      value={form.data.ContactPhone || ''}
                      onChange={form.setters.ContactPhone}
                      inputProps={{ style: { fontSize: '0.8rem' } }}
                      error={showErrors ? form.errors.ContactPhone : null}
                    />
                  </div>
                  <div style={{ width: 80 }}>
                    <PhoneExtField
                      label={null}
                      value={form.data.ContactPhoneExt || ''}
                      onChange={form.setters.ContactPhoneExt}
                      error={showErrors ? form.errors.ContactPhoneExt : null}
                      inputProps={{ style: { fontSize: '0.8rem' } }}
                      placeholder="Ext."
                    />
                  </div>
                </div>
              ) : (
                <span>
                  {FormatPhoneNumber(form.data.ContactPhone)}
                  {!!form.data.ContactPhoneExt &&
                    `, Ext: ${form.data.ContactPhoneExt}`}
                </span>
              )}

              {isEditable && (
                <div>
                  <IconButton
                    size="small"
                    onClick={() => setEditPhone((v: boolean) => !v)}>
                    <DesignSuite24.CommonIcons.IconEdit fontSize="inherit" />
                  </IconButton>
                </div>
              )}
            </div>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />

            <strong style={{ whiteSpace: 'nowrap' }}>Address</strong>
            <span>{member.Address.DisplayAddress}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong>Activation Status</strong>
            <span>
              <CurrentlyActiveField
                active={member.Active || member.IsCurrentlyActive}
              />
            </span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Activation Date
            </strong>
            <span>{formatDate(member.ActivationDate)}</span>
            <hr
              style={{
                gridColumn: '1/-1',
                margin: 0,
                height: 1,
                background: '#e1e1e1',
              }}
            />
            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Deactivation Date
            </strong>
            <span>{formatDate(member.DeactivationDate)}</span>

            <hr style={{ gridColumn: '1/-1' }} />

            <strong style={{ whiteSpace: 'nowrap' }}>Employer</strong>
            <span>{form.data.EmployerName || member.EmployerName}</span>

            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Plan Name
            </strong>
            <span>{member.PlanName}</span>

            <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
              Termination Date
            </strong>
            {dispEmployerTermDate}
          </div>
        </>
      )}
    </div>
  )
}

export default MemberDetails
