import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid, Paper, Typography } from '@material-ui/core'

import ReferralRequestActivity from './ReferralRequestActivity'
import MemberDetailsForm from '../ReferralForm/MemberDetailsForm'
import PhysicianDetailsForm from '../ReferralForm/PhysicianDetailsForm'
import { LoadingSidebar } from '../ReferralForm/LoadingSkeletons'
import ConversationsTabs from '../Conversations'
import ReferralRequestDetailsForm from './ReferralDetailsForm'
import PermissionDenied from '../PermissionDenied'
import ActionsFooter from './ActionsFooter'
import AttachedBundles from '../AttachedBundles'
import { ReferralRequestFormData } from './types'
import usePrevious from '../../hooks/usePrevious'
import useForm from '../../hooks/useForm'
import useApi from '../../hooks/useApi'
import { useAuth } from '../../providers/Auth'
import validators from './validators'
import Exclusions from '../Exclusions' // @todo: SD-2591: bring back
import { useSetFrameHeaderLeft, useSetFrameHeaderMid } from '../AppFrame'
import Masker from '../Masker'
import {
  getReferralRequestPrices,
  saveReferralRequestPrices,
} from '../../actions/PriceActions'
import dateTime from '../../utils/dateTime'

interface Props {
  initialData: ReferralRequestFormData
  isEditing: boolean
  refetch: () => void
}

export const useReferralRequest = (id?: number | null) => {
  const { data, refetch } = useApi(
    id
      ? {
          route: `/referral_request/${id}`,
        }
      : undefined
  )

  return { refetch, data: data ? { ...data, ...data.ReferralRequest } : null }
}

const ReferralRequestForm: React.FC<Props> = ({
  initialData,
  isEditing,
  refetch,
}) => {
  const defaultData = {
    ID: null,
    BundlerID: null,
    CancelledAt: null,
    CancelledBy: null,
    ContactEmail: null,
    ContactPhone: null,
    ContactPhoneExt: null,
    ConversationID: null,
    CreatedByUserID: null,
    Descr: null,
    EmployerMember: null,
    EmployerMemberID: null,
    EmployerName: '',
    EntityName: 'referralRequest' as const,
    MainCptCode: null,
    ModifiedByUserID: null,
    Notes: null,
    OrderingPhysEmail: null,
    OrderingPhysFax: null,
    OrderingPhysName: null,
    OrderingPhysPhone: null,
    OrderingPhysPhoneExt: null,
    OrganizationID: null,
    PCPAddressID: null,
    PracticeFacilityID: null,
    ReferralSource: null,
    ReferralID: null,
    ReferrerID: null,
    ReferrerDivisionID: null,
    RequestedServiceDate: null,
    NextReferralRequestStatuses: ['open'],
    CreatedAt: null,
    CreatedByUser: null,
    UpdatedAt: null,
    StatusID: null,
    View: true,
    Edit: true,
    ContentEditable: true,
  }

  const prevInitialData = usePrevious(initialData) // will be null if loading
  const [attemptedSave, setAttemptedSave] = React.useState(false)
  const [customErrors, setCustomErrors] = React.useState<string[]>([])
  const form = useForm<ReferralRequestFormData>(defaultData, validators)

  React.useEffect(() => {
    // set the initialData when it's finished loading
    if (!prevInitialData && !!initialData) {
      form.setData({ ...form.data, ...initialData })
    }
  }, [initialData])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: 410 }}>
          <MemberDetailsForm form={form} showErrors={attemptedSave} />
          <Exclusions employerID={form.data?.EmployerMember?.EmployerID} />
        </div>
        <div
          style={{
            flex: 1,
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            marginLeft: '1.5rem',
            borderLeft: '2px solid #ddd',
          }}>
          <ReferralRequestDetailsForm
            form={form}
            showErrors={attemptedSave}
            isEditing={isEditing}
            customErrors={customErrors}
          />

          <hr />
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <AttachedBundles
                entityName="referral request"
                isReady={!!form.data.ID && !!form.data.PracticeFacilityID}
                apiGetPrices={() => {
                  return getReferralRequestPrices({ requestID: form.data.ID! })
                }}
                apiSavePrices={({ priceIDs }: { priceIDs: number[] }) => {
                  return saveReferralRequestPrices({
                    requestID: form.data.ID!,
                    priceIDs,
                  })
                }}
                priceListFilters={{
                  referralRequestID: form.data.ID,
                  serviceDate: form.data.RequestedServiceDate,
                }}
              />
            </Grid>
            <Grid item xs>
              <div
                style={{
                  borderLeft: '2px solid #ccc',
                  paddingLeft: '1.5rem',
                }}>
                <PhysicianDetailsForm form={form} showErrors={attemptedSave} />
              </div>
            </Grid>
          </Grid>

          <hr />
          <ActionsFooter
            form={form}
            refetch={refetch}
            isEditing={isEditing}
            setAttemptedSave={setAttemptedSave}
            customErrors={customErrors}
            setCustomErrors={setCustomErrors}
          />
        </div>
      </div>
    </>
  )
}

export const CreateReferralRequest: React.FC<any> = (props) => {
  const member = props.location.state && props.location.state.record

  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">New Referral Request</Typography>
  ))

  const { data: memberContact } = useApi(
    member && member.MemberID
      ? {
          route: `/member/${member.MemberID}/contact_info`,
        }
      : undefined
  )

  if (!member) return <Redirect to="/find_member" />
  if (!memberContact) return null

  return (
    <ReferralRequestForm
      initialData={{
        ...member,
        ContactPhone: memberContact.Phone,
        ContactPhoneExt: memberContact.PhoneExt,
        ContactEmail: memberContact.Email,
      }}
      isEditing={false}
      refetch={() => {}}
    />
  )
}

export const EditReferralRequest: React.FC<any> = (props) => {
  const { access } = useAuth()
  const referralRequestId = props.match.params.referralRequestId
  const { data: referralRequest, refetch } =
    useReferralRequest(+referralRequestId)

  useSetFrameHeaderLeft(() => (
    <Typography variant="h5">Referral Request</Typography>
  ))

  useSetFrameHeaderMid(() => {
    if (!referralRequest?.EmployerMember) return null
    const em = referralRequest.EmployerMember
    return (
      <Typography
        variant="h6"
        style={{ lineHeight: 1, display: 'inline-flex', alignItems: 'center' }}>
        {em.FirstName} {em.LastName}
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.2,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>DOB</strong>
          {dateTime.parse(em.DOB).format()}
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.2,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>Gender</strong>
          {em.Gender}
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.2,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>Last 4 SSN</strong>
          <Masker value={em.SSNLast4} canToggleToView />
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginLeft: '0.75rem',
            paddingLeft: '0.75rem',
            fontSize: '62%',
            lineHeight: 1.2,
            color: '#555',
            borderLeft: '1px solid #bbb',
          }}>
          <strong style={{ display: 'block' }}>Integration ID</strong>
          {em.IntegrationID}
        </Typography>
      </Typography>
    )
  }, [referralRequest?.EmployerMember])

  if (!access.IsZeroUser && !access.Roles.ReferralRequester) {
    return <PermissionDenied />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <ReferralRequestForm
          initialData={referralRequest}
          isEditing
          refetch={refetch}
        />
      </Grid>
      <Grid
        item
        xs={3}
        style={{
          background: '#f1f1f1',
          borderRadius: 8,
          boxShadow: '15px 0px 26px -30px inset',
          padding: '1rem',
        }}>
        {referralRequest ? (
          <div>
            <Paper>
              <ConversationsTabs referralRequestId={referralRequestId} />
            </Paper>
            <Typography variant="h6" style={{ margin: '1rem 0 0.5rem' }}>
              Referral Request Activity
            </Typography>
            <ReferralRequestActivity referralRequestId={referralRequestId} />
          </div>
        ) : (
          <LoadingSidebar />
        )}
      </Grid>
    </Grid>
  )
}
