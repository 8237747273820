import React from 'react'
import { Chip } from '@material-ui/core'
import { ReferralFile } from '../Files'
import { FileActions } from '../../actions'
import { ReferralInvoice } from './types'
import useApi from '../../hooks/useApi'
import { AttachFile as IconAttachFile } from '@material-ui/icons'
import { parseS3Key } from '../../utils'
import styled from 'styled-components'
import DesignSuite24 from '../DesignSuite24'
import AttachedBundles from '../AttachedBundles'
import { getReferralPrices } from '../../actions/PriceActions'
import dateTime from '../../utils/dateTime'

const { REFERRAL_FILE } = FileActions

const ChipGrid = styled.div`
  margin-top: 5px;

  .MuiChip-root {
    max-width: 240px;
    white-space: wrap;
    margin-bottom: 5px;
    margin-right: 5px;
  }
`

interface ReferralDetailProps {
  referral: ReferralInvoice['Referral'] & {
    ID: number
    Gender: string
  }
}

const ReferralDetailSectionComponent: React.FC<ReferralDetailProps> = ({
  referral,
}) => {
  const { data: refFiles } = useApi({
    route: `/referral/${referral.ID}/file`,
  })

  const handleFileClick = ({ ReferralID, FileID }: any) => {
    window.open(
      `/download?id=${ReferralID}&type=${REFERRAL_FILE}&fileId=${FileID}`,
      '_blank'
    )
  }

  const renderFiles = () => {
    if (refFiles && refFiles.length > 0) {
      return (
        <ChipGrid>
          {refFiles.map((file: ReferralFile) => {
            const name = parseS3Key(file.S3Key)
            return (
              <DesignSuite24.Tooltip key={file.S3Key} title={name}>
                <Chip
                  key={file.S3Key}
                  icon={<IconAttachFile />}
                  clickable
                  label={name}
                  onClick={() => handleFileClick(file)}
                  variant="outlined"
                  color="primary"
                />
              </DesignSuite24.Tooltip>
            )
          })}
        </ChipGrid>
      )
    }
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr',
        gridRowGap: '0.5rem',
        gridColumnGap: '1rem',
        alignItems: 'center',
        maxWidth: 450,
        fontSize: '96%',
      }}>
      <strong style={{ whiteSpace: 'nowrap' }}>Member</strong>
      <span></span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
        Name
      </strong>
      <span>{referral.MemberName}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
        DOB
      </strong>
      <span>{dateTime.parse(referral.DOB).format()}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
        Gender
      </strong>
      <span>{referral.Gender}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
        Activation
      </strong>
      <span>
        {dateTime
          .parse(referral.MemberActivationDate)
          .format(dateTime.formats.AmericanDate, 'N/A')}
      </span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', paddingLeft: '0.75rem' }}>
        Deactivation
      </strong>
      <span>
        {referral.MemberDeactivationDate
          ? referral.MemberDeactivationDate
          : 'N/A'}
      </span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap' }}>Description</strong>
      <span>{referral.Descr}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap' }}>Date Of Service</strong>
      <span>{dateTime.parse(referral.DateOfService).format()}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap' }}>Organization</strong>
      <span>{referral.OrganizationName}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap' }}>Facility</strong>
      <span>{referral.PracticeFacilityName}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap' }}>Employer</strong>
      <span>{referral.EmployerName}</span>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <div style={{ gridColumn: '1/-1' }}>
        <strong style={{ display: 'block' }}>Referral Notes</strong>
        {referral.ReferralNotes}
      </div>
      <hr
        style={{
          gridColumn: '1/-1',
          margin: 0,
          height: 1,
          background: '#e1e1e1',
        }}
      />

      <strong style={{ whiteSpace: 'nowrap', alignSelf: 'start' }}>
        Files
      </strong>
      <div>
        <div style={{ marginTop: '-5px' }}>{renderFiles()}</div>
      </div>

      <strong style={{ whiteSpace: 'nowrap', alignSelf: 'start' }}>
        Attached Bundles
      </strong>
      <div>
        <AttachedBundles
          title={null}
          readOnly
          entityName="referral"
          isReady={!!referral?.ID && !!referral?.PracticeFacilityID}
          apiGetPrices={() => {
            return getReferralPrices({ referralID: referral?.ID! })
          }}
        />
      </div>
    </div>
  )
}

export default ReferralDetailSectionComponent
